import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import NewLayout from "../components/new_layout"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import Card from "../components/v2023/UI/Card/card"
import Opinions from "../components/v2023/Opinions/opinions"
import ReadyToTalk from "../components/v2023/ReadyToTalk/ready-to-talk"
import TecalisAdapts from "../components/v2023/TecalisAdapts/tecalis-adapts"
import CustomerLogos from "../components/v2023/CustomerLogos/customer-logos"


const CertifiedCommunication = ({ data, pageContext }) => {
    const page = data.page.edges[0].node
    const lang = pageContext.langKey
    const helpers = data.helpers.frontmatter
    const path = {
        "functionalities": {
            en: "/products/electronic-signature/functionalities",
            es: "/es/productos/firma-electronica/funcionalidades"
        },
        "price": {
            en: "/products/electronic-signature/plans",
            es: "/es/productos/firma-electronica/planes"
        }
    }
    const linkUrl = {
        en: "https://www.tecalis.com/request-demo",
        es: "https://www.tecalis.com/es/solicita-demo"
    }

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={pageContext.langKey}
                title={page.seo.title}
                description={page.seo.meta_description}
                translates={pageContext.translates}
                image={page.seo?.image_2.localFile?.publicURL}
            />

            <main className="main">
                <div className="main__section main__section--50 main__section--50--lg color-section purple-dark m-b-80">
                    <div className="container">
                        <div className="container__50">
                            <div className="tag tag--highlight">{page.name}</div>
                            <h1 className="merriweather">{page.hero_title}</h1>
                            <div className="container__text">
                                <ReactMarkdown children={page.hero_description} rehypePlugins={[rehypeRaw]} />
                            </div>
                            <div className="button__wrapper">
                                <Link to={helpers.request_demo_path}>
                                    <button className="button button--ghost button--xl">{page.hero_button}</button>
                                </Link>
                            </div>
                        </div>
                        <div className="container__50" data-aos="fade-left">
                            <div className="gif">
                                <lottie-player src="/animations/certified-communication.json" background="transparent" speed="1" loop autoplay />
                            </div>
                        </div>
                    </div>
                </div>
                <CustomerLogos />
                <div className="main__section main__section--100">
                    <div className="container">
                        <h2>{page.info_title}</h2>
                        <div className="container__text">
                            <ReactMarkdown
                                children={page.info_description}
                                rehypePlugins={[rehypeRaw]}
                            />
                        </div>
                        <div className="grid-lg-4">
                            {page.info_items.map((item, index) => {
                                const animation = {"data-aos":"zoom-in", "data-aos-delay": 200 * index}
                                return <Card
                                    key={index}
                                    name={item.title}
                                    alternativeText={item.image_new.alternativeText}
                                    image={item.image_new}
                                    description={`${item.short_description}${item.long_description}`}
                                    animation={animation}
                                />
                            })}
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--50 main__section--50-invert main__section--50--sm">
                    <div className="container">
                        <div className="container__50">
                            <h2>{page.tsp_title}</h2>
                            <div className="container__text">
                                <ReactMarkdown children={page.tsp_description} rehypePlugins={[rehypeRaw]} />
                            </div>
                        </div>
                        <div className="container__50">
                            <GatsbyImage alt={page.tsp_image.alternativeText} image={getImage(page.tsp_image_new.localFile)} />
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--100">
                    <div className="container">
                        <h2>{page.use_cases_title}</h2>
                        <div className="container__text">
                            <ReactMarkdown children={page.use_cases_description} rehypePlugins={[rehypeRaw]} />
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--100 color-section purple">
                    <div className="container">
                        <div className="grid-sm-5">
                            {page.use_cases_items.map((item, index) => (
                                <div className="list-highlight" key={index}>
                                    <div className="list-highlight__info">
                                        <h4>{item.title}</h4>
                                        <div className="list-highlight__info__text">
                                            <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <Link to={linkUrl[lang]}>
                            <button className="button button--lg">{page.use_cases_button}</button>
                        </Link>
                    </div>
                </div>
                <div className="main__section main__section--50 main__section--50-invert main__section--50--sm">
                    <div className="container">
                        <div className="container__50">
                            <ReactMarkdown children={page.agility_title_new} rehypePlugins={[rehypeRaw]} />
                            <div className="container__text">
                                <ReactMarkdown children={page.agility_description} rehypePlugins={[rehypeRaw]} />
                            </div>
                        </div>
                        <div className="container__50">
                            <GatsbyImage alt={page.agility_image_new.alternativeText} image={getImage(page.agility_image_new.localFile)} />
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--50 main__section--50--md color-section gray">
                    <div className="container">
                        <div className="container__50">
                            {page.media_items.map((item, index) => (
                                <div className="list-highlight" key={index}>
                                    <div className="list-highlight__number">{item.order}</div>
                                    <div className="list-highlight__info">
                                        <h4>{item.title}</h4>
                                        <div className="list-highlight__info__text">
                                            <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="container__50" data-aos="fade-left">
                            <GatsbyImage alt={page.media_image.alternativeText} image={getImage(page.media_image_new.localFile)} width={500} />
                        </div>
                    </div>
                </div>
                <Opinions lang={lang} />
                <TecalisAdapts lang={lang} />
                <div className="main__section main__section--100" data-aos="zoom-in">
                    <div className="container">
                        <div className="tile">
                            <h3>{page.plans_title}</h3>
                            <ReactMarkdown children={page.plans_description_one} rehypePlugins={[rehypeRaw]} />
                            <div className="tile__body">
                                <ul className="check-list">
                                    <li className="check-list__item">
                                        <ReactMarkdown children={page.plans_description_two} rehypePlugins={[rehypeRaw]} />
                                    </li>
                                    <li className="check-list__item">
                                        <ReactMarkdown children={page.plans_description_three} rehypePlugins={[rehypeRaw]} />
                                    </li>
                                </ul>
                            </div>
                            <Link to={path.price[pageContext.langKey]}>
                                <button className="button">{page.plans_button}</button>
                            </Link>
                        </div>
                    </div>
                </div>
                <ReadyToTalk lang={lang} />
            </main>
        </NewLayout>
    )
}

export default CertifiedCommunication

export const certifiedCommunicationQuery = graphql`
    query ($langKey: String) {
        page: allStrapi2022Communication ( filter: { locale: { eq: $langKey } } ) {
            edges {
                node {
                    seo {
                        title
                        meta_description
                        image_2 {
                            localFile {
                                publicURL

                            }
                        }
                    }
                    name
                    hero_title
                    hero_description
                    hero_button
                    hero_anim_desktop
                    hero_anim_mobile
                    info_title
                    info_description
                    info_items {
                        order
                        title
                        short_description
                        long_description
                        image {
                            name
                            alternativeText
                            localFile {
                                publicURL
                            }
                        }
                        image_new {
                            name
                            alternativeText
                            localFile {
                                publicURL
                            }
                        }
                    }
                    tsp_title
                    tsp_description
                    tsp_image {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    tsp_image_new {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    use_cases_title
                    use_cases_description
                    use_cases_items {
                        title
                        description
                    }
                    use_cases_button
                    agility_title
                    agility_title_new
                    agility_description
                    agility_image {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    agility_image_new {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    media_items {
                        order
                        title
                        description
                    }
                    media_image {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    media_image_new {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    plans_title
                    plans_description_one
                    plans_description_two
                    plans_description_three
                    plans_button
                }
            }
        }
        helpers: markdownRemark (
            frontmatter: {
                lang: {
                    eq: $langKey
                }
                name: {
                    eq: "helpers"
                }
            }
        ) {
            frontmatter {
                trust_us
                last_news
                blog_path
                about_us_path
                request_demo_path
            }
        }
        image: file(relativePath: { eq: "background-blur.png" }) {
            sharp:childImageSharp {
                fluid {
                    srcSetWebp
                }
            }
        }
    }
`
